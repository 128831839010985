import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import * as ReactDOM from "react-dom";
import CloseButton from "../../privateComponents/CloseButton";
import { PORTAL_CONTAINERS } from "../../constants";
export default function Dialog({ children, disableClose, onClose, open, title, ...rest }) {
    const dialogRef = React.useRef(null);
    const closeDialog = React.useCallback(() => {
        if (!dialogRef.current)
            return;
        dialogRef.current.setAttribute("data-closing", "");
        dialogRef.current.addEventListener("animationend", () => {
            if (!dialogRef.current)
                return;
            dialogRef.current.removeAttribute("data-closing");
            dialogRef.current.close();
            onClose();
        }, { once: true });
    }, [onClose]);
    React.useEffect(() => {
        if (!dialogRef.current || dialogRef.current.open === open)
            return;
        if (open)
            return dialogRef.current.showModal();
        closeDialog();
    }, [closeDialog, open]);
    return ReactDOM.createPortal(
    /*eslint-disable-next-line jsx-a11y/click-events-have-key-events*/
    _jsx("dialog", { ...rest, "aria-describedby": "dialog-desc", "aria-labelledby": "dialog-title", className: "br-1 bs-1 p-0", onClick: disableClose
            ? undefined
            : (e) => {
                if (e.target === dialogRef.current)
                    closeDialog();
            }, ref: dialogRef, children: _jsxs("div", { className: "dialog__container", children: [_jsxs("div", { className: "dialog__header", children: [_jsx("h3", { id: "dialog-title", children: title }), _jsx("div", { className: "dialog__close-button-container", children: _jsx(CloseButton, { disabled: disableClose || !open, onClick: () => closeDialog() }) })] }), _jsx("div", { id: "dialog-desc", children: children })] }) }), PORTAL_CONTAINERS.dialog);
}
