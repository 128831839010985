import { jsx as _jsx } from "react/jsx-runtime";
import * as ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { PORTAL_CONTAINERS } from "../../constants";
import { getCssTime0 } from "../../utils/getCssVar";
const PORTAL_EL = PORTAL_CONTAINERS.fab;
export default function Fab({ hide = false, type = "submit", // Formik gets grumpy if you don't specify this
...rest }) {
    if (!PORTAL_EL)
        return;
    return ReactDOM.createPortal(_jsx(CSSTransition, { classNames: "fab-", in: !hide, mountOnEnter: true, timeout: { exit: getCssTime0() + 100 }, unmountOnExit: true, children: _jsx("button", { ...rest, className: "fab br-max p-3 z-1", type: type }) }), PORTAL_EL);
}
