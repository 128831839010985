import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { getCssTime1 } from "../../utils/getCssVar";
export default function Mask({ onClose, open, portalContainer, ...rest }) {
    const handleKeyDown = React.useCallback((e) => {
        if (open && onClose && e.code === "Escape")
            onClose();
    }, [onClose, open]);
    React.useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);
    if (!portalContainer)
        return;
    return ReactDOM.createPortal(
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    _jsxs("div", { onKeyDown: handleKeyDown, children: [_jsx(CSSTransition, { classNames: "mask__mask-", in: open, mountOnEnter: true, timeout: { exit: getCssTime1() + 100 }, unmountOnExit: true, children: _jsx("div", { className: "mask__mask z-3", onClick: onClose }) }), _jsx("div", { ...rest, className: "mask__container z-3" })] }), 
    /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
    portalContainer);
}
