import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CSSTransition } from "react-transition-group";
import CloseButton from "../../privateComponents/CloseButton";
import Mask from "../../privateComponents/Mask";
import NavButton from "./NavButton";
import NavLink from "./NavLink";
import NavList from "./NavList";
import NavSubList from "./NavSubList";
import { PORTAL_CONTAINERS } from "../../constants";
import { getCssTime1 } from "../../utils/getCssVar";
import useIsWideResolution from "../../hooks/useIsWideResolution";
export default function Nav({ children, onClose, open, ...rest }) {
    const isWideResolution = useIsWideResolution();
    const navEl = (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    _jsxs("nav", { ...rest, className: "nav", onClick: (e) => e.stopPropagation(), children: [_jsx("div", { className: "nav__close-button", children: _jsx(CloseButton, { onClick: onClose }) }), children] }));
    return isWideResolution ? (navEl) : (_jsx(Mask, { onClose: onClose, open: open, portalContainer: PORTAL_CONTAINERS.nav, children: _jsx(CSSTransition, { classNames: "nav-", in: open, mountOnEnter: true, timeout: { exit: getCssTime1() + 100 }, unmountOnExit: true, children: navEl }) }));
}
Nav.Button = NavButton;
Nav.Link = NavLink;
Nav.List = NavList;
Nav.SubList = NavSubList;
